<template>
    <div class=" h-100  pt-2 bottom-padding ml-2 mr-2">
        <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center">
            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
        </div>
        <div v-else class="start-overflow">
            <div class="create-company-container w-100 " v-if="permissionProduct">
                <div style="width:100%;" class="mb-5" >
                    <div class="main-div">
                        <div class="create-company-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 pl-3 text-white">Products Permission</span>
                        </div>
                    </div>
                    <div class="page-layout carded left-sidebar"
                        style="border: 0px solid rgb(148, 190, 240) !important;border-radius: 0px !important;">
                        <div class="page-content-wrapper" style="overflow-x:auto">
                            <div class="page-content">
                                <div class="demo-content create-company-container w-100">
                                    <div v-if="$route.path.startsWith('/linkemp/create')" class="pl-7" style="border: 1px solid #00448b; color:#00448b;text-align:left;padding:12px;text-transform:capitalize;border-radius: 10px 10px 0px 0px;font-size: 14px;font-weight: 600;">Set Product Permissions</div>
                                    <div v-else class="pl-7" style="border: 1px solid #00448b;color:#00448b;text-align:left;padding:12px;text-transform:capitalize;border-radius: 10px 10px 0px 0px;font-size: 14px;font-weight: 600;">Set Products Permission</div>
                                    <table class="table table-new-template table-brand" style="border:1px solid grey">
                                        <thead class="text-left">
                                            <tr> 
                                                <th width="1%"></th>
                                                <th>PRODUCTS</th>
                                                <th class="text-center">PERMISSION</th>
                                                <th class="text-center">PRODUCT ACCESS</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr v-if="ajax_call_in_progress" >
                                                <td colspan="5" class="text-center">
                                                    <div class="d-flex align-items-center justify-content-center w-100">
                                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-if="!ajax_call_in_progress && products_data.length == 0" class="text-center font-weight-bold">
                                                <td colspan="5" class="text-center">No Products Found</td>
                                            </tr>
                                            <tr v-else v-for="(product, index) in products_data" :key="index" class="sent-call-tr" >
                                                <td width="1%"></td>
                                                <td class="pl-2">{{product.product_name}}</td>
                                                <td  > 
                                                    <div class="w-100" >
                                                        <div class="ml-auto mr-auto" style="position: relative; justify-content: left;width: 220px;">
                                                            <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                <multiselect class="diginew-multiselect input-field-underline item" name="product_manager" :searchable="true"
                                                                    :options="purchase_manager_type" 
                                                                    :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id))" 
                                                                    label="name" @open="getProductRoles(product.product_id)" @search-change="searchProductRoles" :loading="product_roles_loading"
                                                                    :show-labels="false" v-model="product.role_id" placeholder="Select Permission"
                                                                    :options-limit="280" :max-height="200" :show-no-results="true"
                                                                    :hide-selected="false"
                                                                >
                                                                    <template slot="noOptions">
                                                                        <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                    </template>
                                                                    <template slot="noResult">
                                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                    </template>
                                                                    <template slot="afterList">
                                                                        <div v-observe-visibility="reachEndOfProductRoles" />
                                                                    </template>
                                                                </multiselect>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </td>
                                                <td class="text-center">
                                                    <span class="switch switch-lg mr-2" style="top: 4px;">
                                                        <el-switch 
                                                            :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id))"
                                                            active-color="#00448b"
                                                            inactive-color="#bcb8b8"
                                                            v-model ="product.has_access"
                                                            class="routers">
                                                        </el-switch> 
                                                    </span>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="mt-9 mb-9">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <!-- <button class="btn btn-outline-secondary" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button> -->
                                            <button class="btn btn-outlines-secondarys" v-if="$route.path.startsWith('/linkemp/create')" style="width: 120px;border-radius: 5px;" @click="backToScreen" >Previous</button>
                                            <button class="btn btn-new-success ml-5" style="width: 120px;border-radius: 5px;" @click="save" >Save</button>
                                        </div>
                                    </div>
                                    <div v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id)" class="mt-9 mb-9">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <!-- <button class="btn btn-outline-secondary" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button> -->
                                            <button class="btn btn-outlines-secondarys" v-if="$route.path.startsWith('/linkemp/create')" style="width: 120px;border-radius: 5px;" @click="backToScreen" >Previous</button>
                                            <button class="btn btn-new-success ml-3" style="width: 120px;border-radius: 5px;" @click="save" >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <create-success v-if="create_success" @hideSuccess="hideSuccess" :success_message="success_message" modal_name="create-success"></create-success>
    </div>
    
</template>
<script>
import CreateSuccess from '.././CreateSuccess.vue'
import companies from '../../mixins/companies';
import { SweetModal } from 'sweet-modal-vue';
import {
    HalfCircleSpinner
} from "epic-spinners";
import product from '../../mixins/product';
import globals from '../../globals';
import axios from 'axios';
import { EventBus } from '../../eventBus/nav-bar-event';
    export default {
        mixins: [companies, product],
        props: ["link_data","companyid"],
        data() {
            return {
                permissionProduct: true,
                warning_msg: '',
                success_msg: '',
                permissionProductTab: true,
                purchase_manager_type: [],
                purchase_manager:'',
                productAccess:true,
                productAccountAccess: false,
                skip: 0,
                limit: 10,
                search_key: '',
                products_data: [],
                product_roles_loading: false,
                ajax_call_in_progress: false,
                create_success: false,
                success_message: "",
                isVisible: false,
                count: 0,
                scrollable: false,
                product_id: '',
                company:'',
                
            }
        },
        components: {
            HalfCircleSpinner,
            SweetModal,
            CreateSuccess
        },
        methods: {
            //hide the success modal
            hideSuccess() {
                this.create_success = false;
                this.$router.push("/organization/listview");
            },
            //API call to get the subscribet products list
            async getSubscribedProducts() {
                this.ajax_call_in_progress = true;
                let id = JSON.parse(localStorage.getItem("companyId"))
                /**@params = company_id, @params =search_key*/
                try {
                    let params = {
                        company_id: id ,
                        search_key: ''
                    };
                    let response = await this.getCompaniesSubscribedProducts(params);
                    if(response.status_id == 1) {
                        let prod_data = response.products_list;
                        prod_data.forEach((data, index) => {
                            this.products_data.push({
                                has_access: false,
                                product_id: data.id,
                                product_name: data.product_code,
                                role_id: {
                                    id: "",
                                    name: "",
                                }
                            })
                        })
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    console.log(err);
                }
            
            },
            //APi call to ge tthe product roles
            /**@params = id */
            async getProductRoles(id) {
                this.product_id = id;
                this.purchase_manager_type = [];
                this.product_roles_loading = true;
                /**@params = skip, @params =limit,@params = search_key, @params =product_id*/
                try {
                    let params = {
                        skip: 0,
                        limit: 10,
                        search_key: this.search_key,
                        product_id: this.product_id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.purchase_manager_type = response.response;
                    }
                    this.count = response.response.length;
                    this.product_roles_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            //Api call to search the product roles
            /**@prams = search_key */
            async searchProductRoles(search_key) {
                this.search_key = search_key;
                this.purchase_manager_type = [];
                this.product_roles_loading = true;
                /**@params = skip, @params =limit,@params = search_key, @params =product_id*/
                try {
                    let params = {
                        skip: 0,
                        limit: 10,
                        search_key: this.search_key,
                        product_id: this.product_id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.purchase_manager_type = response.response;
                    }
                    this.count = response.response.length;
                    this.product_roles_loading = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            //Lazy loading of the products roles
            /**@params = reached */
            async reachEndOfProductRoles(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        this.product_roles_loading = true;
                        /**@params = skip, @params =limit,@params = search_key, @params =product_id*/
                            let params = {
                            skip: this.count,
                            limit: 10,
                            search_key: this.search_key,
                            product_id: this.product_id,
                        };
                        let response = await this.getListProductRole(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                tempFiles.forEach(el => {
                                    this.purchase_manager_type.push(el);
                                });
                                this.count += response.response.length;
                                this.product_roles_loading = false
                            } else {
                                this.scrollable = true
                                this.product_roles_loading = false
                            }
                        }
                        this.product_roles_loading = false
                    }
                }
            },
            //cancel the creation of link product permission
            cancel() {
                this.$router.push(`/organization/listview`);
            },
            //back to previous screen 
            backToScreen() {
                this.$emit('switchWizard1');
            },
            //Api call to save the link employee data 
            async save() {
                this.ajax_call_in_progress = true;
                try {
                    let params = JSON.parse(localStorage.getItem("link_emp_data"));
                    params.products = JSON.parse(JSON.stringify(this.products_data));
                    params.products.forEach((data, index) => {
                        if(data.id == '') {
                            delete data.id
                        }
                        data.role_id = data.role_id.id
                    })
                    await axios.post(globals.AUTH_SERVICE + `/employees/link/company`, params).then(response => {
                        if(response.data.status_id == 1) {
                            localStorage.removeItem("link_emp_data");
                            this.success_message = response.data.response;
                            this.create_success = true;
                            setTimeout(() => {
                                this.$modal.show('create-success');
                            }, 500);
                        }
                    }).catch((err) => {
                        this.ajax_call_in_progress = false;
                        this.warning_msg = err.response.data.reason;
                        this.$refs.warning_modal.open(); 
                    }); 
                }
                catch(err){
                    this.ajax_call_in_progress = false;
                    this.warning_msg = err
                    this.$refs.warning_modal.open(); 
                }
            }
        },
        mounted() {
           this.getSubscribedProducts() 
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }   
        },
        beforeDestroy() {
            localStorage.removeItem('companyId');
        },
    }
</script>
<style >
    .page-content-wrapper{
        padding: 0 1.2rem !important;
    }
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
        /*border: 1px solid grey;*/
    }
    .overflow-auto {
        overflow: auto;
    }
    .table-new-template>tbody>tr:nth-of-type(even) td {
        background-color: #fff;
        padding:10px;
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td {
        background-color: #fff;
        padding:10px;
        
    }
    
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect> .multiselect__tags{
        background: #fff!important;
    }
    
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect> .multiselect__tags{
        background: #fff!important;
    }
   
    .table-new-template thead tr th {
        vertical-align: middle;
        color: #005dae;
        text-transform: capitalize;
        font-weight: 600;
        letter-spacing: 1px;
        white-space: nowrap;
        font-size: 14px;
        text-align: left
    }

    .table-new-template thead th, 
    .table-new-template tbody td{
        align-items: center;
        padding: 10px !important;;

    }
    
    
    .table-new-template tbody tr,
    .table-new-template tbody td {
        align-items: center;
        font-weight: 600;
        text-align: left
    }
    .placeholder {
        color: #000!important;   
    }
    .table-new-template>tbody>tr> td div >.diginew-multiselect> .multiselect__tags > .multiselect__placeholder {
        color: #000;
    }
    .btn-new-cancel{
        border-radius: 5px!important;
        background-color: #d51650;
        padding-left: 25px !important;
        padding-right: 25px !important;
        color:#fff!important
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect> .multiselect__tags > .multiselect__input, .multiselect__single{
        background-color:#fff
    }
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect> .multiselect__tags > .multiselect__input, .multiselect__single{
        background: #dfe8f4!important;
    }
    .multiselect__input{
       margin-bottom :0px!important;
   }
   .multiselect__placeholder{
       padding:0px!important;
   }
   @media only screen and (max-width: 768px){
        .bottom-padding{
            padding-bottom: 150px !important;
            margin-right: 5px !important;
        }
    }
    .btn-outlines-secondarys {
        color: #fff;
        background-color: #00448b;
        border-color: #00448b !important;
    }
</style>